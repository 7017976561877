import React, { useEffect } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import NotificationTemplate from "../templates/NotificationTemplate";
import TeamNotificationTemplate from "../templates/TeamNotificationTemplate";
import MySwipableViews from "../components/MySwipableViews";
import { connect } from "react-redux";
import { AppState, setBadgeNumber } from "../state/app";


const tabList = [
    "あなたへの通知",
    <span style={ { fontSize: "0.9rem" } }>チーム内コメント</span>
]

const NotificationPage = ( {
    location,
    setBadgeNumber
}: any ) => {

    const initialTabIndex = Number( location.search.split( '?tab=' )[ 1 ] ) || 0
    typeof window !== "undefined" && window.history.replaceState( '', '', `${ location.pathname }?tab=${ initialTabIndex }` )

    useEffect( () => {
        setBadgeNumber( 0 )
    }, [] )

    const headerOption = {
        headerTitle: "通知",
        headerTitleStyle: { fontSize: "1.1rem" },
        hideBorder: true
    }

    return (
        <Layout headerOption={ headerOption }>
            <SEO title="Notification" />
            <MySwipableViews
                tabList={ tabList }
                location={ location }
                initialTabIndex={ initialTabIndex }>
                <NotificationTemplate
                    index={ 0 } />
                <TeamNotificationTemplate
                    index={ 1 } />
            </MySwipableViews>
        </Layout>
    )
}

const mapStateToProps = ( state: AppState ) => ( {
    badgeNumber: state.app.badgeNumber
} )

const mapDispatchToProps = {
    setBadgeNumber,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( NotificationPage )

