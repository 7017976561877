import { useEffect, useRef, FC, useMemo } from "react"
import { Link } from "gatsby"
import React from "react"
import Styles from "../styles/NotificationItem.module.scss";
import UserAvatar from "./UserAvatar";
import { BundleNotificationType, NoteType, } from "../types/models/Notification";
import { HeartIcon } from "../icons/HeartIcon";
import { FileType } from "../types/models/Post";
import clsx from "clsx"
import { JAPANESE_DICT } from "../constant/const";

type Props = {
    notification: BundleNotificationType,
    isRead: boolean,
    updateNotificationIsReadProperty: ( pk: number | string ) => void
}

export const BundleNotificationItem: FC<Props> = ( {
    notification,
    isRead,
    updateNotificationIsReadProperty
} ) => {

    const notificationItemRef = useRef( null )

    useEffect( () => {
        if ( isRead ) return
        setTimeout( () => {
            updateNotificationIsReadProperty && updateNotificationIsReadProperty( notification.pk )
        }, 5000 )
    }, [ isRead ] )


    const renderThumbnail = () => {
        if ( notification.object.file_type === FileType.VIDEO ) {
            return <img className={ Styles.thumbnail } src={ notification.object.thumbnail } />
        }
        else if ( notification.object.file_type === FileType.IMAGE ) {
            return <img className={ Styles.thumbnail } src={ notification.object.file } />
        }
        return null
    }

    const renderNotificationSentence = () =>
        1 < notification.action.other_actions_count ?
            <p className={ Styles.sentence }>
                <span className={ Styles.action_user_name }>
                    { notification.action.action_user.name + 'さんと他' + notification.action.other_actions_count + '人' }
                </span>
                があなたの{ JAPANESE_DICT[ notification.object.type ] }に{ JAPANESE_DICT[ notification.action.type ] }しました
            </p>
            :
            <p className={ Styles.sentence }>
                <span className={ Styles.action_user_name }>
                    { notification.action.action_user.name }
                </span>
                さんがあなたの{ JAPANESE_DICT[ notification.object.type ] }に{ JAPANESE_DICT[ notification.action.type ] }しました
            </p>

    const renderSymbol = () => {
        if ( notification.note_type === NoteType.LIKE_NOTE ) {
            return <HeartIcon className={ clsx( Styles.symbol, Styles.icon_heart ) } filled />
        }
        else if ( notification.action.symbol ) {
            return <span className={ Styles.symbol }>{ notification.action.symbol }</span>
        }
        else return null
    }



    return (
        useMemo( () => {
            const postUrl = notification.object.comment_pk ? `/post?comment=${ notification.object.comment_pk }` : "/post"
            return ( <div
                className={
                    `${ Styles.container } ${ isRead ? "" : Styles.unread }` }
                ref={ notificationItemRef }>
                <div className={ `${ Styles.content_wrapper }` }>
                    { renderSymbol() }
                    <div className={ Styles.content }>
                        <div className={ Styles.avatar_container }>
                            <UserAvatar
                                userName={ notification.action.action_user.name }
                                userID={ notification.action.action_user.pk }
                                profileImageURL={ notification.action.action_user.profile_image_100 } />
                        </div>
                        <Link
                            to={ postUrl }
                            state={ { postID: notification.object.post_pk } }>
                            { renderNotificationSentence() }
                        </Link>
                        <Link
                            to={ postUrl }
                            state={ { postID: notification.object.post_pk } }
                            style={ { flexDirection: "row" } }>
                            <div className={ Styles.post_content }>
                                <p className={ Styles.post_text }>
                                    { notification.object.text }
                                </p>
                                <div className={ Styles.thumbnail_wrapper }>
                                    { [ 1, 2 ].includes( notification.object.file_type ) &&
                                        renderThumbnail() }
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div> )
        }, [ notification, isRead ] )
    )
}


