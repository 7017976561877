import React, { useState, useEffect, useRef, FC } from "react"
import Styles from "../styles/NotificationTemplate.module.scss";
import NotificationItem from "../components/NotificationItem";
import ActivityIndicator from "../components/atoms/ActivityIndicator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import { updateUserNotificationList, setBadgeNumber, AppState } from "../state/app";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import moment from "moment"
import { BundleNotificationType, isBundleNote, NoteType, SingleNotificationType } from "../types/models/Notification";
import client from "../apis/client";
import { BundleNotificationItem } from "../components/BundleNotificationItem";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";


type Props = {
    userNotificationList: ( SingleNotificationType | BundleNotificationType )[],
    updateUserNotificationList: ( newNoteList: ( SingleNotificationType | BundleNotificationType )[] ) => void,
    setBadgeNumber: ( number: number ) => void,
    index: number
}

const NotificationTemplate: FC<Props> = ( {
    userNotificationList,
    updateUserNotificationList,
    setBadgeNumber,
    index
} ) => {

    const [ firstFetching, setFirstFetching ] = useState( false )
    const [ refreshing, setRefreshing ] = useState( false )
    const [ goingBack, setGoingBack ] = useState( false )
    const [ allDataRetrieved, setAllDataRetrieved ] = useState( false )
    const [ previousLastViewTime, setPreviousLastViewTime ] = useState( moment().format( "YYYY/M/D/H/mm/ss" ) )
    const isLoggedIn = useIsLoggedIn()

    const userNotificationListRef = useRef( userNotificationList )
    const allDataRetrievedRef = useRef( allDataRetrieved )

    useEffect( () => {
        userNotificationListRef.current = userNotificationList
    }, [ userNotificationList ] )

    useEffect( () => {
        allDataRetrievedRef.current = allDataRetrieved
    }, [ allDataRetrieved ] )

    useEffect( () => {
        if ( !isLoggedIn ) return
        userNotificationList.length === 0 ?
            componentDidMount() :
            getNewNotification()
        setBadgeNumber( 0 )
    }, [ isLoggedIn ] )

    const componentDidMount = async () => {
        setFirstFetching( true )
        const response = await client.get( `/api/notification/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true,
        } );
        setFirstFetching( false )
        const previous_last_view_time = response.data.previous_last_view_time ? response.data.previous_last_view_time : null
        updateUserNotificationList( response.data.object_list )
        setPreviousLastViewTime( previous_last_view_time )
    }

    const getCommentNotePostPkList = () =>
        userNotificationListRef.current
            .filter( notification =>
                [ NoteType.COMMENT_NOTE, "mention_note" ].includes( notification.note_type )
            ).map( notification =>
                notification.pk
            )


    const getLikeNotePkList = () => {
        const likeNoteList = userNotificationListRef.current.filter( notification =>
            notification.note_type === NoteType.LIKE_NOTE
        ) as BundleNotificationType[]
        return likeNoteList.map( ( notification: BundleNotificationType ) =>
            'post_' + notification.object.post_pk )
    }

    const getReactionNotePkList = () => {
        const reactionNoteList = userNotificationListRef.current.filter( notification =>
            notification.note_type === NoteType.REACTION_NOTE
        ) as BundleNotificationType[]
        return reactionNoteList.map( ( notification: BundleNotificationType ) =>
            'post_' + notification.object.uuid )
    }

    const getNewNotification = async () => {
        const comment_note_list = getCommentNotePostPkList()
        const like_note_list = getLikeNotePkList()
        const data = new FormData()
        previousLastViewTime && data.append( "previous_last_view_time", previousLastViewTime )
        comment_note_list.forEach( element => data.append( "comment_note_list[]", element as any )
        )
        like_note_list.forEach( element => data.append( "like_note_list[]", element )
        )
        // mention_note_list.forEach( element =>
        //     data.append( "mention_note_list[]", element )
        // )
        setRefreshing( true )
        await client.post( `/api/notification/new/`, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( response => {
                updateUserNotificationList( response.data.new_list.concat( userNotificationListRef.current ) )
                setPreviousLastViewTime( response.data.previous_last_view_time )
            } ).catch( ( error ) => console.log( error.response ) );
        setRefreshing( false )

    }

    const getOldNotification = async () => {
        if ( allDataRetrievedRef.current ) return
        const comment_note_list = getCommentNotePostPkList()
        const like_note_list = getLikeNotePkList()
        const reaction_note_list = getReactionNotePkList()
        const data = new FormData()
        previousLastViewTime && data.append( "previous_last_view_time", previousLastViewTime )
        comment_note_list.forEach( element => data.append( "comment_note_list[]", element as any ) )
        like_note_list.forEach( element => data.append( "like_note_list[]", element ) )
        reaction_note_list.forEach( element => data.append( "reaction_note_list[]", element ) )
        setGoingBack( true )
        await client.post( `/api/notification/old/`,
            data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        } ).then( response => {
            let additionalList = response.data.additional_list
            additionalList.length > 0 ? updateUserNotificationList( userNotificationListRef.current.concat( additionalList ) ) : setAllDataRetrieved( true )
        } ).catch( error => console.log( "error", error.response ) );
        setGoingBack( false )
    }

    const updateNotificationIsReadProperty = ( pk: number | string ) => {
        const newNotificationList = userNotificationListRef.current.map( notification =>
            notification.pk !== pk ?
                { ...notification } :
                { ...notification, is_read: true }
        )
        updateUserNotificationList( newNotificationList.slice() )
    }

    const renderEmpty = () =>
        <div className={ Styles.empty_container }>
            <FontAwesomeIcon style={ { margin: "16px 0", fontSize: 72 } } icon={ faBell } color="rgba(0,0,0,0.3)" />
            <p style={ { color: "rgba(0,0,0,0.54)", marginBottom: 8 } }>新着の通知を表示します</p>
            <p style={ { color: "rgba(0,0,0,0.54)" } }>下に引っ張るか、リロードすると更新できます</p>
        </div>

    const infiniteScroll = useInfiniteScroll( {
        container: typeof document === "undefined" ?
            null :
            document.querySelectorAll( "div[data-swipeable=true]" )[ index ] as HTMLElement,
        goBack: getOldNotification,
        refresh: getNewNotification,
        goingBack,
        refreshing,
    } )

    return (
        <div>
            { refreshing && <ActivityIndicator /> }
            <div className={ Styles.container }
                { ...infiniteScroll }>
                { !refreshing &&
                    <div className={ Styles.hidden_activity_indicator }>
                        <ActivityIndicator />
                    </div> }
                { firstFetching && <ActivityIndicator /> }
                { userNotificationList.length === 0 && renderEmpty() }
                { userNotificationList.map( ( notification, index ) =>
                    isBundleNote( notification ) ?
                        <BundleNotificationItem
                            key={ index }
                            notification={ notification }
                            isRead={ !!notification.is_read }
                            updateNotificationIsReadProperty={ updateNotificationIsReadProperty } /> :
                        <NotificationItem
                            key={ index }
                            notification={ notification as SingleNotificationType }
                            isRead={ notification.is_read }
                            updateNotificationIsReadProperty={ updateNotificationIsReadProperty } />

                ) }
                { goingBack && <ActivityIndicator /> }
            </div>
        </div>
    );
}


const mapStateToProps = ( state: AppState ) => ( {
    badgeNumber: state.app.badgeNumber,
    userNotificationList: state.app.userNotificationList
} )

const mapDispatchToProps = {
    updateUserNotificationList,
    setBadgeNumber
}




export default connect(
    mapStateToProps,
    mapDispatchToProps
)( NotificationTemplate )
