import React, { useState, useEffect, useRef, FC } from "react"
import Styles from "../styles/NotificationTemplate.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import NotificationItem from "../components/NotificationItem";
import ActivityIndicator from "../components/atoms/ActivityIndicator";
import { updateTeamNotificationList, updateTeamNotificationNextURL, AppState } from "../state/app";
import { connect } from "react-redux";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { NotificationType, SingleNotificationType } from "../types/models/Notification";
import client from "../apis/client";
import { Link } from "gatsby";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";



type Props = {
    updateTeamNotificationList: ( noteList: NotificationType[] ) => void,
    teamNotificationList: NotificationType[],
    updateTeamNotificationNextURL: ( url: string ) => void,
    teamNotificationNextURL: string | null,
    index: number
}

const TeamNotificationTemplate: FC<Props> = ( {
    updateTeamNotificationList,
    teamNotificationList,
    updateTeamNotificationNextURL,
    teamNotificationNextURL,
    index
} ) => {

    const [ firstFetching, setFirstFetching ] = useState( false )
    const [ refreshing, setRefreshing ] = useState( false )
    const [ goingBack, setGoingBack ] = useState( false )
    const [ allDataRetrieved, setAllDataRetrieved ] = useState( false )
    const [ fetchAtLeastOnce, setFetchAtLeastOnce ] = useState( false )
    const [ belongToTeam, setBelongToTeam ] = useState( true )
    const [ forceRenderTrigger, setForceRenderTrigger ] = useState( false )
    const isLoggedIn = useIsLoggedIn()


    const teamNotificationListRef = useRef( teamNotificationList )
    const teamNotificationNextURLRef = useRef( teamNotificationNextURL )
    const allDataRetrievedRef = useRef( allDataRetrieved )

    useEffect( () => {
        const newTeamNotificationList = teamNotificationList.map( notification =>
            notification.is_read ?
                notification :
                { ...notification, is_read: updateReadStatus( notification ) } )
        teamNotificationListRef.current = newTeamNotificationList
        setForceRenderTrigger( !forceRenderTrigger )
        localStorage.setItem( "lastTeamNotificationViewTime", JSON.stringify( new Date() ) )
    }, [ teamNotificationList ] )

    useEffect( () => {
        teamNotificationNextURLRef.current = teamNotificationNextURL
    }, [ teamNotificationNextURL ] )

    useEffect( () => {
        allDataRetrievedRef.current = allDataRetrieved
    }, [ allDataRetrieved ] )



    useEffect( () => {
        isLoggedIn && teamNotificationList.length === 0 && componentDidMount()
    }, [ isLoggedIn ] )

    const componentDidMount = async () => {

        setFirstFetching( true )
        await client.get( `/api/v1.0/team_comment/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( response => {
                updateTeamNotificationList( response.data.results )
                updateTeamNotificationNextURL( response.data.next )
                setFetchAtLeastOnce( true )
            } )
            .catch( error => {
                if ( error.response.status === 400 ) {
                    setFetchAtLeastOnce( true )
                    setBelongToTeam( false )
                }
            } )
        setFirstFetching( false )
    }

    const getCommentPkList = () => teamNotificationListRef.current.map( comment => comment.pk )

    const getNewNotification = async () => {
        const params = {
            refresh: true,
            comment_list: getCommentPkList()
        }
        setRefreshing( true )
        await client.get( `/api/v1.0/team_comment/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params
        } )
            .then( response => {
                updateTeamNotificationList( response.data.additional_list.concat( teamNotificationListRef.current ) )
            } ).catch( ( error ) => console.log( error.response ) );
        setRefreshing( false )

    }

    const getOldNotification = async () => {
        if ( allDataRetrievedRef.current ) return
        if ( !teamNotificationNextURLRef.current ) return

        setGoingBack( true )
        await client.get( teamNotificationNextURLRef.current, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        } )
            .then( response => {
                let additionalList = response.data.results as NotificationType[]
                additionalList = additionalList.filter( comment =>
                    !teamNotificationListRef.current.map( comment => comment.pk ).includes( comment.pk ) )
                additionalList.length > 0 ?
                    updateTeamNotificationList( teamNotificationListRef.current.concat( additionalList ) )
                    :
                    setAllDataRetrieved( true )
                updateTeamNotificationNextURL( response.data.next )
            } )
            .catch( error => console.log( "error", error.response ) );
        setGoingBack( false )
    }

    const updateNotificationIsReadProperty = ( pk: number | string ) => {
        const newTeamNotificationList = teamNotificationListRef.current.map( notification =>
            notification.pk !== pk ?
                { ...notification } :
                { ...notification, is_read: true }
        )
        updateTeamNotificationList( newTeamNotificationList )
    }

    const updateReadStatus = ( notification: NotificationType ) => {
        const lastTeamNotificationViewTimeStr = localStorage.getItem( "lastTeamNotificationViewTime" )
        const lastTeamNotificationViewTime = lastTeamNotificationViewTimeStr ?
            JSON.parse( lastTeamNotificationViewTimeStr ) :
            null
        if ( !lastTeamNotificationViewTime ) return false
        return new Date( lastTeamNotificationViewTime ) > new Date( notification.created_at )
    }

    const renderEmpty = () =>
        <div className={ Styles.empty_container }>
            <FontAwesomeIcon style={ { margin: "16px 0", fontSize: 72 } } icon={ faBell } color="rgba(0,0,0,0.3)" />
            <p style={ { color: "rgba(0,0,0,0.54)", marginBottom: 16 } }>新着のチームのコメントを表示します</p>
            { !belongToTeam &&
                <Link to="/team_info" style={ { fontWeight: "bold" } }>チーム参加/作成のページへ飛ぶ</Link> }
        </div>

    const infiniteScroll = useInfiniteScroll( {
        container: typeof document === "undefined" ?
            null :
            document.querySelectorAll( "div[data-swipeable=true]" )[ index ] as HTMLElement,
        goBack: getOldNotification,
        refresh: getNewNotification,
        goingBack,
        refreshing,
    } )

    return (
        <div>
            { refreshing &&
                <ActivityIndicator /> }
            <div className={ Styles.container }
                { ...infiniteScroll }>
                {/* { ( fetchAtLeastOnce && !belongToTeam ) && <p className={ Styles.not_belong_to_team }>チームに所属していません</p> } */ }
                { !refreshing &&
                    <div className={ Styles.hidden_activity_indicator }>
                        <ActivityIndicator />
                    </div> }
                { firstFetching &&
                    <ActivityIndicator /> }
                { teamNotificationListRef.current.length === 0 && fetchAtLeastOnce && renderEmpty() }
                { teamNotificationListRef.current.map( ( notification, index ) =>
                    <NotificationItem
                        key={ index }
                        updateNotificationIsReadProperty={ updateNotificationIsReadProperty }
                        isRead={ notification.is_read }
                        notification={ {
                            ...notification,
                            note_type: "team_comment"
                        } as SingleNotificationType } /> ) }
                { goingBack &&
                    <ActivityIndicator /> }
            </div>
        </div>
    );
}

const mapStateToProps = ( state: AppState ) => ( {
    teamNotificationList: state.app.teamNotificationList,
    teamNotificationNextURL: state.app.teamNotificationNextURL
} )

const mapDispatchToProps = {
    updateTeamNotificationList,
    updateTeamNotificationNextURL
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( TeamNotificationTemplate )
