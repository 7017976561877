import { FileType, FileTypeType } from "./Post"
import { OutputAuthorType } from "./User"
export const NoteType = {
    LIKE_NOTE: "like_note",
    COMMENT_NOTE: "comment_note",
    MENTION_NOTE: "mention_note",
    TEAM_COMMENT: "team_comment",
    REACTION_NOTE: "reaction_note"
} as const

export type NoteTypeType = typeof NoteType[ keyof typeof NoteType ]

type VideoNoteType = {
    file: string
    thumbnail: string
}

type BaseNotificationType = {
    note_type: NoteTypeType;
    is_read: boolean
    post_pk: string;
    post_file_type: number;
    post_text: string;
    post_file: string;
    post_thumbnail: string;
    created_at: string;
    pk: number;

}

export type LikeNoteType = {
    like_user: OutputAuthorType;
    user_name_last: string;
    user_name_first: string;
    user_pk: number;
    number: number;
} & BaseNotificationType

export type CommentNoteType = {
    type: string;
    obj_file: string;
    obj_pk: string;
    obj_obj_pk: string;
    author: OutputAuthorType;
    author_pen_name_last: string;
    author_pen_name_first: string;
    comment_is_anonymous: boolean;
    comment_pk: string
    text: string;
    to_what: string;
    is_read: boolean
} & BaseNotificationType

export type TeamCommentNoteType = {
    note_type: NoteTypeType
    text: string;
    post_thumbnail: string;
    post_file_type: number;
    post_text: string;
    post_pk: string;
    post_author: OutputAuthorType
    pk: string;
    comment_pk: string
    comment_author: OutputAuthorType;
    created_at: string;
    is_read: boolean
}

export type MentionNoteType = {
    author: OutputAuthorType;
    comment_is_anonymous: boolean;
    comment_pk: string | null
    text: string;
    to_what: string;
} & BaseNotificationType

export type NotificationType = CommentNoteType | MentionNoteType | TeamCommentNoteType | BundleNotificationType


export const isBundleNote = ( note: NotificationType ): note is BundleNotificationType => note.note_type === NoteType.LIKE_NOTE || note.note_type === NoteType.REACTION_NOTE
// export const isNewLikeNote = ( note: NotificationType ): note is NewLikeNoteType => note.note_type === NoteType.LIKE_NOTE
export const isTeamCommentNote = ( note: NotificationType ): note is TeamCommentNoteType => note.note_type === NoteType.TEAM_COMMENT

// export const isNoteAboutVideo = ( note: NotificationType ): note is T extends VideoNoteType =>note.post_file_type === FileType.VIDEO

export type NewLikeNoteType = {
    note_type: NoteTypeType,
    object: {
        text: string
        file_type: FileTypeType
        pk: string
        file: string
        thumbnail: string
    }
    action_user: OutputAuthorType;
    created_at: string;
    is_read: boolean
    other_actions_count: number;
}

export type ReactionNoteType = {
    note_type: NoteTypeType
    object: {
        text: string
        file_type: FileTypeType
        post_pkpk: string
        file: string
        thumbnail: string
        comment_pk: string | null
    }
    action_user: OutputAuthorType;
    created_at: string;
    is_read: boolean
    other_actions_count: number;
}

export type BundleNotificationType = {
    note_type: "like_note" | "reaction_note",
    object: {
        type: "Post" | "Comment"
        text: string
        file_type: FileTypeType
        post_pk: string | number
        file: string
        thumbnail: string
        uuid: string
        comment_pk: string | null | undefined
    }
    action: {
        type: "reaction" | "like"
        action_user: OutputAuthorType;
        other_actions_count: number;
        symbol?: string
    }
    created_at: string;
    is_read: boolean
    pk: string
}

export type SingleNotificationType = ( CommentNoteType | MentionNoteType | TeamCommentNoteType ) & { note_type: "comment_note" | "mention_note" | "team_comment" }
